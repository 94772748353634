<template>
    <div>
        <b-sidebar aria-labelledby="sidebar-no-header-title" no-header id="show_statment" backdrop style="direction:ltr" width="50rem" right :title="$parent.lang.statment" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.statment}}</span>
                    </div>
                    <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm closethis">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                
                <v-row :style="`direction:`+$parent.lang.dir">
                <v-col
                cols="12"
                md="8"
                sm="12">
                <b-input-group class="mt-3">
                    <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                    <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                    <b-input-group-append style="border-left:1px solid #CCC">
                        <b-button variant="light" style="background: #ccc !important;font-size:14px;color:#000;font-size:14px;padding:8px 22px;border-radius:5px" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="text-right">
                    <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{$parent.lang.export_excel}}</b-button>
                    <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" sm="12" :style="`direction:`+$parent.lang.dir">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th colspan="5" class="text-center">{{$parent.lang.account_statement}} - {{account_name}}</th>
                            </tr>
                            <tr>
                                <th class="text-center">{{$parent.lang.date}}</th>
                                <th class="text-center">{{$parent.lang.description}}</th>
                                <th class="text-center">{{$parent.lang.depit}}</th>
                                <th class="text-center">{{$parent.lang.credit}}</th>
                                <th class="text-center">{{$parent.lang.balance}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in accountrows" :key="index">
                                <td class="text-center" style="direction:ltr">{{item.date}}</td>
                                <td class="text-center" style="direction:ltr">{{item.notes}}</td>
                                <td class="text-center" style="direction:ltr">{{item.depit}}</td>
                                <td class="text-center" style="direction:ltr">{{item.credit}}</td>
                                <td class="text-center" style="direction:ltr">{{item.balance}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="5"> {{$parent.lang.balance}} : {{btotals}} </th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
            </v-row>

            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            accountn: {
                type_name: '',
                type_name_en: ''
            },
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            accountrows: [],
        }
    },
    computed: {
        account_name: function() {
            if(this.$parent.lang.langname=='ar')
            {
                return this.accountn.type_name
            }else{
                return this.accountn.type_name_en;
            }
        },
        btotals: function()
        {
            let t = 0;
            let tc = 0;
            let td = 0;
            for(let i=0;i<this.accountrows.length;i++){
                tc = parseFloat(tc) + parseFloat(this.accountrows[i].credit)
                td = parseFloat(td) + parseFloat(this.accountrows[i].depit)
            }
            t = parseFloat(td) - parseFloat(tc);
            return this.$RoundNum(t);
        }
    },
    methods: {
        getReport(){
            const post = new FormData();
            post.append('type',"getStatReport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.accountn.classid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data;
                }
            })
        }
    },
}
</script>