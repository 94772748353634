<template>
    <div>
        <b-sidebar aria-labelledby="sidebar-no-header-title" no-header id="add_paytype" backdrop style="direction:ltr" width="50rem" right :title="$parent.lang.add_pay_type" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.add_pay_type}}</span>
                    </div>
                    <div @click="hide" id="closeaddpayment" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm closethis">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <v-row :style="`direction:`+$parent.lang.dir" class="pa-2 m-2">
                    <v-col cols="12" md="6" sm="12">
                        <label>{{$parent.lang.namear}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.namear"
                            v-model="type_name" 
                            :rules="fieldRules"
                        >{{ type_name }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <label>{{$parent.lang.nameen}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.nameen"
                            v-model="type_name_en" 
                            :rules="fieldRules"
                        >{{ type_name_en }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.classid}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.classid"
                            v-model="classid" 
                            readonly
                            @change="checkClass()"
                        >{{ classid }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="10" sm="12">
                        <label>{{$parent.lang.class_name}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.class_name"
                            v-model="class_name"
                            list="my-list-id"
                            @change="changeClassid()"
                        ></b-form-input>
                        <datalist id="my-list-id">
                            <option v-for="(item,index) in classes" :key="index">{{item.classid}} - {{ item.namear }}</option>
                        </datalist>
                        
                    </v-col>
                </v-row>
            </template>
            <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                        <b-button type="button" @click="addPaytype();" variant="success" class="ma-2" style="width:100px;">حفظ</b-button>
                    </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            type_name: '',
            type_name_en: '',
            classid: '',
            id: '',
            class_name: '',
            classes: [],
            fieldRules: []
        }
    },
    created() {
        this.getClasses();
    },
    methods: {
        getClassname(classid){
            for(let i =0; i < this.classes.length;i++){
                if(this.classes[i].classid == classid){
                    this.class_name = this.classes[i].classid + ' - ' + this.classes[i].namear
                }
            }
        },
        changeClassid(){
            for(let i =0; i < this.classes.length;i++){
                if(this.classes[i].classid + ' - ' + this.classes[i].namear == this.class_name){
                    this.classid = this.classes[i].classid
                }
            }
        },
        getClasses(){
            const post = new FormData();
            post.append("type","getPostedCOA")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
    
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.classes = res.results.data;
            })
        },
        
        addPaytype(){
            let vald = true;
            let mess = '';
            if(this.type_name == ''){
                vald = false;
                mess = 'يرجى ادخال اسم طريقة الدفع بالعربي';
            }
            if(this.type_name_en == ''){
                vald = false;
                mess = 'يرجى ادخال اسم طريقة الدفع بالانجليزي';
            }
            if(vald == false){
                this.$snotify.error(mess, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('type',"doaddeditePT");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[type_name]',this.type_name);
            post.append('data[type_name_en]',this.type_name_en);
            post.append('data[classid]',this.classid);
            post.append('data[id]',this.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    let message = res.results.data;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.$parent.getPyamentType();
                    document.getElementById('closeaddpayment').click();
                }
            })
        }
    },
}
</script>