<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />

    <div>
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row :style="`margin-top:0;direction:`+lang.dir+`;padding-left:30px;padding-right:30xp;`">
            <v-col cols="12" class="text-end">
                <b-button id="multiply-button" :class="`mt-10 multiply-button-`+lang.lalgin" 
                :style="`width:150px;background:transparent;border:none`" 
                @click="resetPayType()"
                v-b-toggle.add_paytype>
              <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_pay_type}}</div></b-button>
            </v-col>
        </v-row>
        <v-simple-table class="mt-5" style="padding-left:30px;padding-right:30xp;">
            <thead>
                <tr>
                    <th class="backBlack text-center">{{lang.namear}}</th>
                    <th class="backBlack text-center">{{lang.nameen}}</th>
                    <th class="backBlack text-center">{{lang.classid}}</th>
                    <th class="backBlack text-center" colspan="2">{{lang.action}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in paytypesrows" :key="index">
                    <td>{{item.type_name}}</td>
                    <td>{{item.type_name_en}}</td>
                    <td>{{item.classid}}</td>
                    <td style="width:100px;background:#000;border-bottom:1px solid #fff !important;padding:1px">
                        <b-button class="btn-sm btn-default" style="color:#FFF;background:#000;width:100px;height:25px !important;margin-left:5px;border:none" @click="edit(item)" v-b-toggle.add_paytype>{{lang.update}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                    </td>
                    <td style="width:100px;background:green;border-bottom:1px solid #fff !important;padding:1px">
                        <b-button class="btn-sm btn-default" style="color:#FFF;background:green;width:100px;height:25px !important;margin-left:5px;border:none" @click="getStatement(item)" v-b-toggle.show_statment>{{lang.client_statement}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <finance-statement ref="showStat" />
        <add-pay-type ref="addPaytype" />
    </div>
        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import FinanceStatement from '@/components/finance-statement.vue';
import AddPayType from '@/components/addPayType.vue';
import Footer from '@/components/Footer.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import ExportsPrint from '@/components/exports-print.vue'
export default {
    components: { breadCrumbs, TabsComp, FinanceStatement, AddPayType, HeaderPortrate,Footer,ExportsPrint },
    data() {
        return {
            paytypesrows: [],
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            accountrows: [],
        }
    },
    created() {
        this.getPyamentType();
    },
    methods: {
        edit(item){
            this.$refs.addPaytype.type_name = item.type_name;
            this.$refs.addPaytype.type_name_en = item.type_name_en;
            this.$refs.addPaytype.classid = item.classid;
            this.$refs.addPaytype.id = item.id;
            this.$refs.addPaytype.getClassname(item.classid);
        },
        resetPayType(){
            this.$refs.addPaytype.type_name = '';
            this.$refs.addPaytype.type_name_en = '';
            this.$refs.addPaytype.classid = '';
            this.$refs.addPaytype.id = '';
            this.$refs.addPaytype.class_name = '';
        },
        getStatement(item){
            this.$refs.showStat.accountn = item;
        },
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.paytypesrows = res.results.data;
                
            })
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items: function() {
            return {
                    text: this.lang.paytypes,
                    disabled: true,
                    to: '/finance/paytypes',
                }
        },
       
    },
}
</script>